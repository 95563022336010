<!--电能管理--设置--拓扑图设置-->
<template>
    <div id="topologySet" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 头部 -->
        <div class="header">
            <my-headerTitle>{{ $t('topologySet.a1') }}</my-headerTitle>
        </div>
        <!-- 内容区域 -->
        <div class="main-empty" v-if="treeData.length == 0">
           <a-empty>
               <a-button type="primary" @click="openNodeAdd()">
                    {{ $t('topologySet.a2') }}
                </a-button>
            </a-empty>
        </div>
        <div class="main" v-else>
            <!-- 左侧树形数据展示 -->
            <div class="tree">
                <a-tree :tree-data="treeData" :blockNode="true" :replace-fields="replaceFields" @select="nodeSelect">
                <template slot="custom" slot-scope="item">
                    <div class="box" v-if="silence">
                        <!-- 目录名称 -->
                        <span>{{ item.name }}</span>
                        <div class="operation">
                            <!-- 添加 -->
                            <a-icon v-if="item.isTreeLast" style="font-size: 16px;margin:0 10px" type="plus-circle" @click.stop="openNodeAdd(item)"/>
                            <!-- 名称修改 -->
                            <a-icon style="font-size: 16px;" type="edit" @click.stop="openNodeAmend(item)" />
                            <!-- 删除 -->
                            <a-icon style="font-size: 16px;margin:0 10px" type="delete" @click.stop="nodeDelete(item)"/>
                        </div>
                    </div>
                </template>
                </a-tree>
            </div>
            <!-- 右侧内容区域 -->
            <div v-if="nodeInfo.name == ''" class="empty">
                <a-empty>
                    <span class="emptyfont">{{ $t('topologySet.a3') }}</span>
                </a-empty>
            </div>
            <div v-else class="content">
                <!-- 右侧内容区域--头部 -->
                <div class="content-title">
                    <span>{{ $t('topologySet.a4') }}{{nodeInfo.name}}</span>
                </div>
                <div class="content-main">
                    <!-- 对应运算点/虚拟点 -->
                    <div class="operationPointTitle">
                        <!-- 对应运算点/虚拟点标题 -->
                        <div class="tableTitle">
                            <div>
                                <span>{{ $t('topologySet.a5') }}</span>
                                <!--  -->
                            </div>
                            <a-button type="primary" ghost size="default" :disabled="nodeInfo.children.length !== 0" @click="openOperationPoint">
                                {{ $t('topologySet.a6') }}
                            </a-button>
                        </div>
                        <!-- 对应运算点/虚拟点表格 -->
                        <a-table
                            size="small"
                            :columns="gaugeTableColumns" 
                            :data-source="gaugeTableData"  
                            :pagination="pagination"
                            :row-selection="{ selectedRowKeys: gaugeTableRowKeys, onChange: gaugeTableChange }"
                            :rowKey="(record) => record.id"
                        />
                    </div>
                    <!-- 分组设置 -->
                    <div class="content-floor">
                        <!-- 分组设置表格 -->
                        <div class="content-floor-left">
                            <div class="tableTitle">
                                <span>{{ $t('topologySet.a7') }}</span>
                                <a-button type="primary" ghost :disabled="nodeInfo.children.length !== 0" @click="openGrouping">
                                    {{ $t('topologySet.a8') }}
                                </a-button>
                            </div>
                            <!-- 分组数据渲染 -->
                            <div class="menuContent">
                                <a-menu v-model="menu" v-if="groupingData.length !== 0">
                                    <a-menu-item v-for="item in groupingData" :key="item.id" @click="getGroupingInfo(item)">
                                        <div class="menu-li">
                                            <span>{{ item.name }}</span>
                                            <div>
                                                <a-button type="primary" style="margin-right:15px" @click="openGroupingCompile(item)">
                                                    {{ $t('topologySet.a9') }}
                                                </a-button>
                                                <a-button type="danger" @click="groupingDelete(item)">
                                                    {{ $t('topologySet.a10') }}
                                                </a-button>
                                            </div>
                                        </div>
                                    </a-menu-item>
                                </a-menu>
                                <a-empty v-else/>
                            </div>
                        </div>
                        <!-- 分组计量点列表 -->
                        <transition name="slide-fade">
                            <div class="content-floor-right" v-if="groupingInfo.name !== undefined">
                                    <div class="tableTitle">
                                        <div>{{ groupingInfo.name }}{{ $t('topologySet.a5') }}</div>
                                        <div>
                                            <a-button type="primary" style="margin-right:15px" ghost @click="addGroupingGaugeTable" :disabled="gaugeTableRowKeys.length == 0">
                                                {{ $t('topologySet.a8') }}
                                            </a-button>
                                            <a-button type="primary" @click="deleteGroupingGaugeTable" :disabled="groupingGaugeTableDataSelect.length == 0">
                                                {{ $t('topologySet.a11') }}
                                            </a-button>
                                        </div>
                                    </div>
                                    <a-table 
                                        size="small"
                                        :columns="groupingGaugeTableDataColumns" 
                                        :data-source="groupingGaugeTableData" 
                                        :pagination="pagination"
                                        :row-selection="{ selectedRowKeys: grGaugeTableRowKeys, onChange: grGaugeTableChange }"
                                        :rowKey="(record) => record.id"
                                    />
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <!-- 节点添加 -->
        <a-modal v-model="nodeAddVisible" :title="$t('topologySet.a12')" centered :width="400" @cancel="nodeAddCancel" :destroyOnClose="true" v-drag>
            <template slot="footer">
                <a-button key="back" @click="nodeAddCancel">
                    {{ $t('topologySet.a13') }}
                </a-button>
                <a-button key="submit" type="primary" @click="nodeAddOk" :disabled="disabled">
                    {{ $t('topologySet.a14') }}
                </a-button>
            </template>
            <div class="padding">
                <div v-loading="nodeAddLoading" :element-loading-text="$t('alarm.a0')">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            <div style="margin-bottom:24px">
                                {{ $t('topologySet.a15') }}
                            </div>
                        </a-col>
                        <a-col :span="16">
                            <a-form-model ref="ruleForm" :model="nodeAddData" :rules="rules">
                                <a-form-model-item prop="name">
                                    <a-input v-model="nodeAddData.name" :maxLength="30" :placeholder="$t('topologySet.a16')" allow-clear/>
                                </a-form-model-item>
                            </a-form-model>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom:15px" type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            {{ $t('topologySet.a17') }}
                        </a-col>
                        <a-col :span="16">
                            <a-select v-model="nodeAddData.energyType" style="width: 100%" >
                                <a-select-option :value="0">
                                    {{ $t('topologySet.a18') }}
                                </a-select-option>
                                <a-select-option :value="1">
                                    {{ $t('topologySet.a19') }}
                                </a-select-option>
                                <a-select-option :value="2">
                                    {{ $t('topologySet.a20') }}
                                </a-select-option>
                                <a-select-option :value="3">
                                    {{ $t('topologySet.a21') }}
                                </a-select-option>
                                <a-select-option :value="99">
                                    {{ $t('topologySet.a22') }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom:15px" type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            {{ $t('topologySet.a23') }}
                        </a-col>
                        <a-col :span="16">
                            <a-select v-model="nodeAddData.pointType" style="width: 100%" @change="addTypeSelect">
                                <a-select-option :value="4">
                                    {{ $t('topologySet.a27') }}
                                </a-select-option>
                                <a-select-option :value="0">
                                    {{ $t('topologySet.a36') }}
                                </a-select-option>
                                <a-select-option :value="1">
                                    {{ $t('topologySet.a24') }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom:15px" type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            {{ $t('topologySet.a25') }}
                        </a-col>
                        <a-col :span="16">
                            <a-select v-model="nodeAddData.pointId" style="width: 100%">
                                <a-select-option v-for="(item,index) in linkedList" :key="index" :value="item.id">
                                    <span v-if="item.entryName == undefined">
                                        {{ item.name }}
                                    </span>
                                    <span v-else>
                                        {{ item.entryName }}
                                    </span>
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </a-modal>
        <!-- 修改节点名称 -->
        <a-modal v-model="nodeAmendVisible" :title="$t('topologySet.a26')" centered :width="400"  @cancel="nodeAmendCancel" :destroyOnClose="true" v-drag>
            <template slot="footer">
                <a-button key="back" @click="nodeAmendCancel">
                    {{ $t('topologySet.a13') }}
                </a-button>
                <a-button key="submit" type="primary" @click="nodeAmendOk" :disabled="disabled">
                    {{ $t('topologySet.a14') }}
                </a-button>
            </template>
            <div class="padding">
                <div v-loading="nodeAmendLoading" :element-loading-text="$t('alarm.a0')">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            <div style="margin-bottom:24px">
                                {{ $t('topologySet.a33') }}
                            </div>
                        </a-col>
                        <a-col :span="16">
                            <a-form-model ref="amendForm" :model="nodeAmendData" :rules="rules">
                                <a-form-model-item prop="name">
                                    <a-input v-model="nodeAmendData.name" :maxLength="30" :placeholder="$t('topologySet.a16')" allow-clear />
                                </a-form-model-item>
                            </a-form-model>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom:15px" type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            {{ $t('topologySet.a17') }}
                        </a-col>
                        <a-col :span="16">
                            <a-select v-model="nodeAmendData.energyType" style="width: 100%" >
                                <a-select-option :value="0">
                                    {{ $t('topologySet.a18') }}
                                </a-select-option>
                                <a-select-option :value="1">
                                    {{ $t('topologySet.a19') }}
                                </a-select-option>
                                <a-select-option :value="2">
                                    {{ $t('topologySet.a20') }}
                                </a-select-option>
                                <a-select-option :value="3">
                                    {{ $t('topologySet.a21') }}
                                </a-select-option>
                                <a-select-option :value="99">
                                    {{ $t('topologySet.a22') }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom:15px" type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            {{ $t('topologySet.a23') }}
                        </a-col>
                        <a-col :span="16">
                            <a-select v-model="nodeAmendData.pointType" style="width: 100%" @change="amendTypeSelect">
                                <a-select-option :value="4">
                                    {{ $t('topologySet.a27') }}
                                </a-select-option>
                                <a-select-option :value="0">
                                    {{ $t('topologySet.a36') }}
                                </a-select-option>
                                <a-select-option :value="1">
                                    {{ $t('topologySet.a24') }}
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                    <a-row style="margin-bottom:15px" type="flex" justify="space-around" align="middle">
                        <a-col :span="8">
                            {{ $t('topologySet.a25') }}
                        </a-col>
                        <a-col :span="16">
                            <a-select v-model="nodeAmendData.pointId" style="width: 100%">
                                <a-select-option v-for="(item,index) in linkedList" :key="index" :value="item.id">
                                    <span v-if="item.entryName == undefined">
                                        {{ item.name }}
                                    </span>
                                    <span v-else>
                                        {{ item.entryName }}
                                    </span>
                                </a-select-option>
                            </a-select>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </a-modal>
        <!-- 计量点编辑 -->
        <a-modal v-model="measureVisible" :title="$t('topologySet.a28')" centered :width="800" @cancel="geugeTableCancel" :destroyOnClose="true" v-drag>
            <template slot="footer">
                <a-button key="back" @click="geugeTableCancel">
                    {{ $t('topologySet.a13') }}
                </a-button>
                <a-button key="submit" type="primary" @click="setGeugeTable" :disabled="disabled">
                    {{ $t('topologySet.a14') }}
                </a-button>
            </template>
            <div v-loading="modalLoading" :element-loading-text="$t('alarm.a0')">
                <div class="modal-main">
                    <div class="modal-main-title">
                        <a-input-search :placeholder="$t('topologySet.a37')" style="width: 200px" @change="onSearch" />
                    </div>
                    <a-table
                        size="small" 
                        :scroll="{y:150}"
                        :columns="gaugeTableColumns" 
                        :data-source="unregisteredGaugeTable" 
                        :pagination="pagination" 
                        :row-selection="{ selectedRowKeys: unregisteredGaugeTableRowKeys, onChange: unregisteredGaugeTableChange }"
                        :rowKey="(record) => record.id"
                    />
                    <div class="modal-main-but">
                        <a-button type="primary" style="margin-right:20px" @click="geugeTableAdd" :disabled="unregisteredGaugeTableRowKeys.length == 0">
                            {{ $t('topologySet.a29') }}
                        </a-button>
                        <a-button type="primary" ghost @click="geugeTableDelete" :disabled="registerGeugeTableRowKeys.length == 0">
                            {{ $t('topologySet.a30') }}
                        </a-button>
                    </div>
                    <a-table
                        size="small" 
                        :scroll="{y:150}"
                        :columns="gaugeTableColumns" 
                        :data-source="registerGeugeTable" 
                        :pagination="pagination" 
                        :row-selection="{ selectedRowKeys: registerGeugeTableRowKeys, onChange: registerGeugeTableChange }"
                        :rowKey="(record) => record.id"
                />
                </div>
            </div>
        </a-modal>
        <!-- 分组添加 -->
        <a-modal v-model="groupingVisible" :title="$t('topologySet.a31')" centered :width="400" @cancel="groupingCancel" :destroyOnClose="true" v-drag>
            <template slot="footer">
                <a-button key="back" @click="groupingCancel">
                    {{ $t('topologySet.a13') }}
                </a-button>
                <a-button key="submit" type="primary" @click="groupingAdd" :disabled="disabled">
                    {{ $t('topologySet.a14') }}
                </a-button>
            </template>
            <div class="padding">
                <div v-loading="modalLoading" :element-loading-text="$t('alarm.a0')">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="5">
                            <div style="margin-bottom:24px">
                                {{ $t('topologySet.a33') }}
                            </div>
                        </a-col>
                        <a-col :span="16">
                            <a-form-model ref="groupingForm" :model="grAddData" :rules="rules">
                                <a-form-model-item prop="name">
                                    <a-input v-model="grAddData.name" :maxLength="30" :placeholder="$t('topologySet.a16')" allow-clear/>
                                </a-form-model-item>
                            </a-form-model>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </a-modal>
        <!-- 分组编辑 -->
        <a-modal v-model="groupingCompileVisible" :title="$t('topologySet.a9')" centered :width="400" @cancel="groupingCompileCancel" :destroyOnClose="true" v-drag>
            <template slot="footer">
                <a-button key="back" @click="groupingCompileCancel">
                    {{ $t('topologySet.a13') }}
                </a-button>
                <a-button key="submit" type="primary" @click="groupingCompile" :disabled="disabled">
                    {{ $t('topologySet.a14') }}
                </a-button>
            </template>
            <div class="padding">
                <div v-loading="modalLoading" :element-loading-text="$t('alarm.a0')">
                    <a-row type="flex" justify="space-around" align="middle">
                        <a-col :span="5">
                            <div style="margin-bottom:24px">
                                {{ $t('topologySet.a33') }}
                            </div>
                        </a-col>
                        <a-col :span="16">
                            <a-form-model ref="grCompileData" :model="grCompileData" :rules="rules">
                                <a-form-model-item prop="name">
                                    <a-input v-model="grCompileData.name" :maxLength="30" :placeholder="$t('topologySet.a16')" allow-clear/>
                                </a-form-model-item>
                            </a-form-model>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </a-modal>
        
    </div>
</template>

<script>
import headerTitle from '../../../components/Title/headerTitle.vue'
import { getTopologySetTree,getGRList,amendTreeData,deleteTreeNode,getTreeTopology,amendTreeDatas,getOperationList,getVirtualList } from '../../../api/energy'

export default {
    components:{
        'my-headerTitle':headerTitle
    },
    data() {
        return {
            loading:false,
            modalLoading:false,
            treeData: [],
            replaceFields: {
                children: 'children',
                title: 'name',
                key:'id'
            },
            disabled: false,
            nodeAddLoading: false,
            nodeAddVisible: false,
            nodeAddData:{
                energyType:0,
                name:'',
                pointId:0,
                pointType:4,
            },
            nodeAddDataCopy:{
                energyType:0,
                name:'',
                pointId:0,
                pointType:4
            },
            rules: {
                name: [
                    { required: true, message: this.$t('topologySet.a16'), trigger: 'blur' },
                ],
            },
            GrList:[],
            nodeAmendVisible: false,
            nodeAmendLoading: false,
            nodeAmendData:{
                name:''
            },
            nodeInfo:{
                name:'',
                id:0,
                energyPointRelation:{
                    pointType:0
                }
            },
            nodeData:{},
            gaugeTableDatas: [],
            gaugeTableData: [],
            gaugeTableDataSelect: [],
            gaugeTableRowKeys:[],
            unregisteredGaugeTable: [],
            unregisteredGaugeTableCopy: [],
            unregisteredGaugeTableSelect: [],
            unregisteredGaugeTableRowKeys :[],
            registerGeugeTable: [],
            registerGeugeTableSelect: [],
            registerGeugeTableRowKeys: [],
            gaugeTableColumns: [
                {   
                    title: 'Gr1',
                    dataIndex: 'gr1',
                    key: 'gr1',
                },
                {
                    title: 'Gr2',
                    dataIndex: 'gr2',
                    key: 'gr2',
                },
                {
                    title: 'Gr3',
                    dataIndex: 'gr3',
                    key: 'gr3',
                },
                {
                    title: this.$t('topologySet.a32'),
                    key: 'name',
                    dataIndex: 'name',
                },
            ],
            groupingGaugeTableDataColumns: [
                {
                    title: this.$t('topologySet.a38'),
                    dataIndex: 'name',
                    key: 'name',
                    align:'center'
                },
            ],
            measureVisible: false,
            nodeNameVisible: false,
            groupingData: [],
            groupingInfo: {},
            groupingVisible:false,
            grAddData:{
                name:'',
                pointId:0,
                pointType:3
            },
            grCompileData:{
                name:''
            },
            grCompileDataCopy:{
                name:''
            },
            grGaugeTableRowKeys:[],
            groupingGaugeTableDatas:[],
            groupingGaugeTableData:[],
            groupingGaugeTableDataSelect: [],
            groupingCompileVisible:false,
            pagination: {
                total: 0, //数据总数
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
                showQuickJumper: true, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                onShowSizeChange: (current, pageSize) => {
                this.pagination.defaultCurrent = 1;
                this.pagination.pageSize = pageSize;
                },
                // 改变每页数量时更新显示
                onChange: (current, size) => {
                this.pagination.defaultCurrent = current;
                this.pagination.pageSize = size;
                },
            },
            nodeCache: [],
            menu:[],
            virtualList:[],
            operationList:[],
            linkedList:[],  
            delayeringData:[],
            silence:true,
            aa:'1'
        }
  },
  // 页面初始获取侧边树形数据
 async mounted(){
      this.loading = true
      const data = { siteId:this.$route.query.id };
    //   console.log("getTopologySetTree par:",data);
      // 获取侧边栏树形数据API  
     await getTopologySetTree(data)
      .then(res=>{
          console.log('获取侧边栏树形数据API',res)
          if(res.data !== undefined){
              let data = [res.data]
              this.recursion(data)
              const _treeData = JSON.stringify(data)
              const treeData = JSON.parse(_treeData)
              this.treeData = treeData
          }
      })
      .catch(err=>{
          console.log(err)
      })
      // 获取全部计量表
     await getGRList(data)
      .then(res=>{
          console.log(res)
          const { data } = res
          this.GrList = data
          this.linkedList = data
          this.gaugeTableDatas = data
          this.nodeAddData.pointId = data[0].id
          this.nodeAddDataCopy.pointId = data[0].id
      })
      .catch(err=>{
          console.log(err)
      })
     await getOperationList(data)
      .then(res=>{
        //   console.log('运算点列表',res)
          const { data } = res
          this.operationList = data
      })
      .catch(err=>{
          console.log(err)
      })
     await getVirtualList(data)
      .then(res=>{
        //   console.log('虚拟点列表',res)
          const { data } = res
          this.virtualList = data
          this.loading = false
      })
      .catch(err=>{
          console.log(err)
          this.loading = false
      })
  },
   methods: {
        // 树节点选择
        nodeSelect(selectedKeys, info) {
            this.gaugeTableRowKeys = []
            this.registerGeugeTableSelect = []
            this.unregisteredGaugeTableSelect = []
            this.registerGeugeTableRowKeys = []
            this.unregisteredGaugeTableRowKeys = []
            this.menu = []
            this.groupingInfo = {}
            const id = this.nodeInfo.id
            if(info.selected && selectedKeys[0] !== id){
                    this.loading = true
                    const nodeData = info.node.dataRef
                    this.nodeInfo = nodeData
                    const data = { relationId:selectedKeys[0],siteId:this.$route.query.id }
                    getTreeTopology(data)
                    .then(res=>{
                        console.log(res)
                        const { data:{ children }} = res
                        this.dataClassification(children)
                        if(children.length == 0){
                            this.modifyIsTreeLast(this.treeData,selectedKeys[0])
                        }
                        this.loading = false
                    })
                    .catch(err=>{
                        console.log(err)
                        this.loading = false
                    })
                }else{
                    return false
                }
        },
        // 数据分类
        dataClassification(children){
            const gaugeTableDatas = this.gaugeTableDatas
            const groupingData = []
            const gaugeTableData = []
            const arr = []
            for(let i = 0;i < children.length;i++){
                const pointType = children[i].energyPointRelation.pointType
                const node = children[i].energyPointGr
                if(pointType == 2){
                    if(node!=null)
                        arr.push(node.id)
                    if(children[i].energyPointRelation.pointId == 0){
                        if(children[i].energyPointGr!=null)
                            gaugeTableData.push(children[i].energyPointGr)
                    }
                }else if(pointType == 3){
                    groupingData.push(children[i].energyPointRelation)
                }
            }
            const unregisteredGaugeTable = gaugeTableDatas.filter((item)=>  arr.indexOf(item.id) == -1 )
            this.groupingData = groupingData
            this.gaugeTableData = gaugeTableData
            this.registerGeugeTable = gaugeTableData
            this.unregisteredGaugeTable = unregisteredGaugeTable
            this.unregisteredGaugeTableCopy = unregisteredGaugeTable
            this.groupingGaugeTableDatas = children
        },
        // 打开节点添加弹框
        openNodeAdd(value){
            this.nodeAddVisible = true
            if(value){
                this.nodeCache = value
                this.nodeAddData.parentId = value.id
                this.nodeAddData.siteId = value.energyPointRelation.siteId
                this.nodeAddData.pointType = value.energyPointRelation.pointType
                const pointType = value.energyPointRelation.pointType
                if(pointType == 0){
                    this.linkedList = this.operationList
                    this.nodeAddData.pointId = this.operationList[0].id
                }else if(pointType == 1){
                    this.linkedList = this.virtualList
                    this.nodeAddData.pointId = this.virtualList[0].id
                }else if(pointType == 4){
                    this.linkedList = this.GrList
                    this.nodeAddData.pointId = this.GrList[0].id
                }
            }else{
                this.nodeAddData.parentId = 0
                this.nodeAddData.siteId = this.$route.query.id
            }
        },
        // 关闭节点新增弹框
        nodeAddCancel(){
            this.nodeAddVisible = false
            const _data = JSON.stringify(this.nodeAddDataCopy)
            const data = JSON.parse(_data)
            this.nodeAddData = data
        },
        // 节点新增方法
        nodeAddOk(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.nodeAddLoading = true
                    this.disabled = true
                    const data = this.nodeAddData
                    console.log(data)
                    amendTreeData(data)
                    .then(res=>{
                        console.log(res)
                        this.nodeAddLoading = false
                        this.disabled = false
                        const { data } = res
                        const nodeData = {children:[],energyPointRelation:data,id:data.id,name:data.name,scopedSlots:{ title:"custom" },isTreeLast: false}
                        if(res.errorCode == '00'){
                            if(this.nodeCache.length !== 0){
                                this.nodeCache.children.push(nodeData)
                            }else{
                                this.treeData.push(nodeData)
                            }
                            this.$message.success(res.msg)
                            this.nodeAddCancel()
                        }else{
                            this.$message.error(res.msg)
                        }
                    })
                    .catch(err=>{
                        console.log(err)
                    })
                } else {
                    return false;
                }
            });
        },
        // 打开节点修改弹框
        openNodeAmend(value){
            console.log(value)
            this.nodeCache = value
            const _data = JSON.stringify(value.energyPointRelation)
            const data = JSON.parse(_data)
            this.nodeAmendData = data
            this.nodeAmendVisible = true
            const pointType = value.energyPointRelation.pointType
            if(pointType == 0){
                this.linkedList = this.operationList
            }else if(pointType == 1){
                this.linkedList = this.virtualList
            }else if(pointType == 4){
                this.linkedList = this.GrList
            }
        },
        // 关闭节点修改弹框
        nodeAmendCancel(){
            this.nodeAmendVisible = false
        },
        // 节点修改方法
        nodeAmendOk(){
            this.$refs.amendForm.validate(valid => {
                if (valid) {
                    const name = this.nodeAmendData.name
                    const id = this.nodeAmendData.id
                    const index = this.delayeringData.findIndex(item=>{
                        return item.name == name && item.id !== id
                    })
                    console.log(index)
                    if(index !== -1){
                        this.$message.error(this.$t('topologySet.a34'))
                    }else{
                        this.nodeAmendLoading = true
                        this.disabled = true
                        const data = this.nodeAmendData
                        console.log("amendTreeData parameter:",data)
                        amendTreeData(data)
                        .then(res=>{
                            console.log("amendTreeData res:",res)
                            this.nodeAmendLoading = false
                            this.disabled = false  
                            const { data } = res
                            if(res.errorCode == '00'){
                                console.log("amendTreeData res(00):",this.nodeCache)
                                this.nameModification(this.treeData,data.name,data.id)
                                this.$message.success(res.msg)
                                this.nodeAmendCancel()
                            }else{
                                this.$message.error(res.msg)
                            }
                        })
                        .catch(err=>{
                            console.log(err)
                        })
                    }
                } else {
                    return false;
                }
            })
        },
        // 名称修改API
        nameModification(arr,name,id){
            for(let i = 0;i < arr.length;i++){
                if(arr[i].id == id){ arr[i].name = name }
                if(arr[i].children!=null&&arr[i].children.length !== 0){ this.nameModification(arr[i].children,name,id)}
            }
        },
        // 删除节点方法
        nodeDelete(value){
            console.log(value)
            console.log(this.nodeInfo)
            this.$confirm({
                title: this.$t('topologySet.a35'),
                centered: true,                
                onOk:()=> {
                    return new Promise((resolve, reject) => {
                        const data = value.energyPointRelation
                        deleteTreeNode(data)
                        .then(res=>{
                            if(res.errorCode == '00'){
                                this.$message.success(res.msg)
                                this.treeNodeDelete(this.treeData,value.id)
                                if(value.energyPointRelation.id == this.nodeInfo.id){
                                    this.nodeInfo.name = ''
                                }
                            }else{
                                this.$message.error(res.msg)
                            }
                            resolve()
                        })
                        .catch(err=>{
                            console.log(err)
                        })
                    })
                    .catch(() => {})
                },
            })
        },
        // 树节点删除方法
        treeNodeDelete(arr,id){
            for(let i = 0; i < arr.length; i++){
                if(arr[i].id == id){
                    arr.splice(i,1)
                }else{
                    if(arr[i].children.length !== 0){
                        this.treeNodeDelete(arr[i].children,id)
                    }
                }
            }
        },
        // 打开计量点编辑弹框  
        openOperationPoint(){
            this.measureVisible = true
        },
        // 添加功能是否显判断
        modifyIsTreeLast(arr,id){
            for(let i = 0;i < arr.length;i++){
                if(arr[i].id == id){ arr[i].isTreeLast = true }
                if(arr[i].children!=null&&arr[i].children.length !== 0){
                    this.modifyIsTreeLast(arr[i].children,id)
                }
            }
        },
        // 递归方法
        recursion(arr){
            for(let i = 0; i < arr.length; i++){
                this.delayeringData.push(arr[i])
                arr[i].scopedSlots = { title:'custom' } 
                arr[i].isTreeLast = false
                if(arr[i].children!=null&&arr[i].children.length !== 0){//modified by li_yj on 2022-7-18 children为null出错问题
                    this.recursion(arr[i].children)
                }
            }
        },
        // 未注册计量表设备名称搜索方法
        onSearch(e) {
            const value = e.target.value
            const data = this.unregisteredGaugeTableCopy
            const arr = data.filter((item)=> item.name.indexOf(e.target.value) !== -1)
            this.unregisteredGaugeTable = arr
        },
        // 计量表取消方法
        geugeTableCancel(){
            this.measureVisible = false
        },
        // 未注册计量表勾选方法
        unregisteredGaugeTableChange(selectedRowKeys, selectedRows) {
            this.unregisteredGaugeTableRowKeys = selectedRowKeys
            this.unregisteredGaugeTableSelect = selectedRows
        },
        // 计量表编辑增加方法
        geugeTableAdd(){
            const energyPointGrList = [ ...this.registerGeugeTable , ...this.unregisteredGaugeTableSelect ]
            const arr = this.unregisteredGaugeTableRowKeys
            const unregisteredGaugeTable = this.unregisteredGaugeTable.filter((item)=>  arr.indexOf(item.id) == -1 )
            this.unregisteredGaugeTable = unregisteredGaugeTable
            this.unregisteredGaugeTableCopy = unregisteredGaugeTable
            this.unregisteredGaugeTableRowKeys = []
            this.registerGeugeTable = energyPointGrList 
            this.unregisteredGaugeTableSelect = []
        },
        // 计量表编辑删除方法
        geugeTableDelete(){
            const arr = this.registerGeugeTableRowKeys
            const registerGeugeTable = this.registerGeugeTable.filter((item)=>  arr.indexOf(item.id) == -1 )
            this.unregisteredGaugeTable = [...this.unregisteredGaugeTable,...this.registerGeugeTableSelect]
            this.unregisteredGaugeTableCopy = [...this.unregisteredGaugeTable,...this.registerGeugeTableSelect]
            this.registerGeugeTable = registerGeugeTable
            this.registerGeugeTableRowKeys = []
            this.registerGeugeTableSelect = []
        },
        // 计量表编辑确认方法
        setGeugeTable(){
            this.modalLoading = true
            this.disabled = true
            const energyPointGrList = this.registerGeugeTable
            const energyPointRelation = this.nodeInfo.energyPointRelation
            const data = { energyPointGrList , energyPointRelation }
            console.log(data)
            amendTreeDatas(data)
            .then(res=>{
                this.modalLoading = false
                this.disabled = false
                if(res.errorCode == '00'){
                    this.$message.success(res.msg)
                    this.gaugeTableData = energyPointGrList
                    const grID = this.groupingGaugeTableDatas.filter((item)=> item.energyPointGr !== null).map((item)=>{ return item.energyPointGr.id}) 
                    const arr = energyPointGrList.filter((item) => grID.indexOf(item.id) == -1).map((item)=>{ return { children:[],energyPointGr:item,energyPointRelation:{pointType:2}}})
                    this.groupingGaugeTableDatas = [...this.groupingGaugeTableDatas,...arr]
                    this.addShow()
                }else{
                    this.$message.error(res.msg)
                }
            })
            .catch(err=>{
                console.log(err)
                this.modalLoading = false
                this.disabled = false
            })
        },
        // 已注册计量表勾选方法
        registerGeugeTableChange(selectedRowKeys, selectedRows) {
            this.registerGeugeTableRowKeys = selectedRowKeys
            this.registerGeugeTableSelect = selectedRows
        },
        // 打开分组添加弹框
        openGrouping(){
            this.groupingVisible = true
        },
        // 添加分组方法
        groupingAdd(){
            this.$refs.groupingForm.validate(valid => {
                if (valid) {
                    this.modalLoading = true
                    this.disabled = true
                    const data = this.grAddData
                    data.energyType = this.nodeInfo.energyPointRelation.energyType
                    data.siteId = this.nodeInfo.energyPointRelation.siteId
                    data.parentId = this.nodeInfo.energyPointRelation.id
                    amendTreeData(data)
                    .then(res=>{
                        const { data } = res
                        if(res.errorCode == '00'){
                            this.$message.success(res.msg)
                            this.groupingData.push(data)
                            this.groupingVisible = false
                            this.grAddData.name = ''
                            this.addShow()
                        }else{
                            this.$message.error(res.msg)
                        }
                        this.modalLoading = false
                        this.disabled = false
                    })
                    .catch(err=>{
                        console.log(err)
                        this.modalLoading = false
                        this.disabled = false
                    })
                } else {
                    return false;
                }
            })
        },
        // 关闭分组添加弹框
        groupingCancel(){
            this.grAddData.name = ''
            this.groupingVisible = false
        },
        // 打开分组编辑弹框
        openGroupingCompile(value){
            const _data = JSON.stringify(value)
            const data = JSON.parse(_data)
            this.grCompileDataCopy = value
            this.grCompileData = data
            this.groupingCompileVisible = true
        },
        // 分组编辑方法
        groupingCompile(){
            this.$refs.grCompileData.validate(valid => {
                if (valid) {
                    this.modalLoading = true
                    this.disabled = true
                    const data = this.grCompileData
                    amendTreeData(data)
                    .then(res=>{
                        const { data } = res
                        if(res.errorCode == '00'){
                                this.$message.success(res.msg)
                                this.grCompileDataCopy.name = data.name
                                this.groupingCompileVisible = false
                                this.nodeInfo.isTreeLast = false
                            }else{
                                this.$message.error(res.msg)
                            }
                        this.modalLoading = false
                        this.disabled = false
                    })
                    .catch(err=>{
                        console.log(err)
                        this.modalLoading = false
                        this.disabled = false
                    })
                } else {
                    return false;
                }
            })
        },
        // 关闭分组编辑弹框
        groupingCompileCancel(){
            this.groupingCompileVisible = false
        },
        // 分组删除
        groupingDelete(value){
            console.log(value)
            this.$confirm({
                title: this.$t('topologySet.a35'),
                centered: true,
                onOk: ()=> {
                    return new Promise((resolve, reject) => {
                        const data = this.groupingData
                        deleteTreeNode(value)
                        .then(res=>{
                            if(res.errorCode == '00'){
                                this.$message.success(res.msg)
                                const groupingData = data.filter((item)=> item.id !== value.id)
                                this.groupingData = groupingData
                                if(this.groupingInfo.id == value.id){
                                    this.groupingInfo = {}
                                }
                                this.gaugeTableData = [...this.gaugeTableData,...this.groupingGaugeTableData]
                                this.unregisteredGaugeTable = this.gaugeTableData
                                this.addShow()
                            }else{
                                this.$message.error(res.msg)
                            }
                            resolve()
                        })
                        .catch(err=>{
                            console.log(err)
                        })
                    })
                    .catch(() => {})
                },
            })
        },
        // 获取分组选择信息
        getGroupingInfo(value){
            this.groupingInfo = value
            this.groupingGaugeTableDataSelect = []
            this.grGaugeTableRowKeys = []
            const id = value.id
            const data = this.groupingGaugeTableDatas
            const arr = []
            for(let i = 0;i < data.length;i++){
                if(data[i].energyPointRelation.pointId == id){
                    arr.push(data[i].energyPointGr)
                }
            }
            this.groupingGaugeTableData = arr
        },
        // 计量点列表勾选方法
        gaugeTableChange(selectedRowKeys, selectedRows){
            this.gaugeTableRowKeys = selectedRowKeys
            this.gaugeTableDataSelect = selectedRows
        },
        // 添加分组计量点列表
        addGroupingGaugeTable(){
            this.loading = true
            const gaugeTableDataSelect = this.gaugeTableDataSelect
            const gaugeTableRowKeys = this.gaugeTableRowKeys
            const energyPointGrList = [...this.groupingGaugeTableData,...gaugeTableDataSelect]
            const energyPointRelation = this.groupingInfo
            const data = { energyPointGrList,energyPointRelation }
            const pointId = this.groupingInfo.id
            console.log(data)
            amendTreeDatas(data)
            .then(res=>{
                if(res.errorCode == '00'){
                    this.$message.success(res.msg)
                    this.groupingGaugeTableDatas = this.groupingGaugeTableDatas.map((item)=>{
                        if(item.energyPointGr !== null && gaugeTableRowKeys.indexOf(item.energyPointGr.id) !== -1){
                            item.energyPointRelation.pointId = pointId
                            return item
                        }else{
                            return item
                        } 
                    })
                    this.groupingGaugeTableData = [...this.groupingGaugeTableData,...this.gaugeTableDataSelect]
                    const arr = this.gaugeTableData.filter((item)=> gaugeTableRowKeys.indexOf(item.id) == -1)
                    this.gaugeTableData = arr
                    this.registerGeugeTable = arr
                    this.gaugeTableRowKeys = []
                    this.loading = false
                }else{
                    this.$message.error(res.msg)
                }
                
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 删除分组计量点列表
        deleteGroupingGaugeTable(){
            this.loading = true
            const groupingGaugeTableData = this.groupingGaugeTableData
            const grGaugeTableRowKeys = this.grGaugeTableRowKeys
            const energyPointGrList = groupingGaugeTableData.filter((item)=> grGaugeTableRowKeys.indexOf(item.id) == -1)
            const energyPointRelation = this.groupingInfo
            const data = { energyPointGrList,energyPointRelation }
            console.log(data)
            amendTreeDatas(data)
            .then(res=>{
                if(res.errorCode == '00'){
                    this.$message.success(res.msg)
                    this.groupingGaugeTableDatas = this.groupingGaugeTableDatas.map((item)=>{ 
                        if(item.energyPointGr !== null && grGaugeTableRowKeys.indexOf(item.energyPointGr.id) !== -1){
                            item.energyPointRelation.pointId = 0
                            return item
                        }else{
                            return item
                        } 
                    })
                    this.groupingGaugeTableData = energyPointGrList
                    this.gaugeTableData = [...this.gaugeTableData,...this.groupingGaugeTableDataSelect]
                    this.registerGeugeTable = this.gaugeTableData
                    this.grGaugeTableRowKeys = []
                    this.loading = false
                }else{
                    this.$message.error(res.msg)
                }
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 设置分组计量点列表
        setGroupingGaugeTable(){
            const energyPointGrList = this.groupingGaugeTableData
            const energyPointRelation = this.groupingInfo
            const data = { energyPointGrList,energyPointRelation }
            amendTreeDatas(data)
            .then(res=>{
                console.log(res)
            })
            .catch(err=>{
                console.log(err)
            })
        },
        // 分组计量表列表勾选方法
        grGaugeTableChange(selectedRowKeys, selectedRows){
            this.grGaugeTableRowKeys  = selectedRowKeys
            this.groupingGaugeTableDataSelect = selectedRows
        },
        // 根据操作计量表和分组判断添加按钮是否显示
        addShow(){
            const groupingDataLength = this.groupingData.length
            const gaugeTableDataLength = this.gaugeTableData.length
            if(groupingDataLength !== 0 || gaugeTableDataLength !== 0){
                this.nodeInfo.isTreeLast = false
            }else{
               this.nodeInfo.isTreeLast = true 
            }
        },
        // 节点添加关联点类型选择
        addTypeSelect(value) {
            if(value == 0){
                this.linkedList = this.operationList
                this.nodeAddData.pointId = this.operationList[0].id
            }else if(value == 1){
                this.linkedList = this.virtualList
                this.nodeAddData.pointId = this.virtualList[0].id
            }else if(value == 4){
                this.linkedList = this.GrList
                this.nodeAddData.pointId = this.GrList[0].id
            }
        },
        // 节点修改关联点类型选择
        amendTypeSelect(value){
            if(value == 0){
                this.linkedList = this.operationList
                this.nodeAmendData.pointId = this.operationList[0].id
            }else if(value == 1){
                this.linkedList = this.virtualList
                this.nodeAmendData.pointId = this.virtualList[0].id
            }else if(value == 4){
                this.linkedList = this.GrList
                this.nodeAmendData.pointId = this.GrList[0].id
            }
        }
    }
}
</script>

<style scoped>
#topologySet{
    width: 100%;
    height: 100%;
}
.header{ 
    padding: 0px 20px;
}
.main{
    display: flex;
    height: calc(100% - 51px);
}
.tree{
    width: 300px;
    height: 100%;
    padding: 10px 0px 0px 10px;
    border-right: 1px solid rgba(12, 12, 12, 0.1);
    overflow: auto;
}
.box{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.operation{
    display: flex;
    align-items: center;
}
.content{
    width: calc(100% - 300px);
    height: 100%;
}
.content-title{
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 10px 0px 10px 0px;
    margin: 0px 20px 15px 15px;
    border-bottom: 1px solid rgba(12, 12, 12, 0.1) ;
}
.content-title::before{
    content: '';
    display: inline-block;
    width: 4px;
    height: 25px;
    background-color: #7682CE;
    margin-right: 10px;
}
.content-main{
    height: calc(100% - 61px);
    padding: 0px 15px;
    overflow: auto;
}
.tableTitle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f3f3f5;
    font-size: 16px;
}
.operationPointTitle{
    margin-bottom: 20px;
}
.content-floor{
    display: flex;
    margin-bottom: 20px;
}
.content-floor-left{
    width: calc(50% - 8px);
    margin-right: 15px;
}
.content-floor-right{
     width: calc(50% - 8px);
}
.menuContent{
    width: 100%;
    border: 1px solid rgba(12, 12, 12, 0.1);
    border-radius: 3px;
    padding: 10px 0px;
}
div /deep/.ant-modal-body{
    padding: 0px;
}
div /deep/.ant-modal-header{
    background-color: #7682CE;
}
.modal-main{
    height: 600px;
    padding: 10px 20px;
    overflow: auto;
}
.modal-main-title{
    margin-bottom: 15px;
}
.modal-main-query{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.modal-main-but{
    display: flex;
    justify-content: center;
    padding: 10px 0px;
}
.padding{
    padding: 20px;
}
.empty{
    width: calc(100% - 300px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.emptyfont{
    font-size: 20px;
    color: #7682CE;
}
.main-empty{
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 51px);
}
.menu-li{
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>